<template>
  <div class="short_video">
    <!--      <Video v-if="videoInfo" :videoShow="true" :videoInfo="videoInfo" :index="0"></Video>-->
    <swiper
      class="video-swiper"
      :options="swiperOption"
      ref="mySwiper"
      v-show="videoList.length > 0"
    >
      <swiper-slide
        class="my_swiper_silde"
        v-for="(item, index) in videoList"
        :key="'item.id' + index"
      >
        <Video
          v-if="showVideo"
          @openSelectLine="openSelectLine"
          :videoShow="itemIndex === index"
          :videoInfo="item"
          :index="index"
        />
      </swiper-slide>
    </swiper>
    <!-- 切换路线框 -->
    <van-popover v-model="isOpen" placement="bottom-end">
      <div class="lineBox">
        <div
          class="line"
          :class="{ selectedLine: item.desc == videoRoadLine.desc }"
          @click.stop="selectLine(item)"
          v-for="item in roadLines"
          :key="item.desc"
        >
          {{ item.desc }}
        </div>
      </div>
    </van-popover>

    <!-- <SelectionLinePopup
      v-if="showChangeRoadLine"
      @closeSelectLine="closeSelectLine"
      @close="showChangeRoadLine = false"
    /> -->
  </div>
</template>
<script>
import Video from "@/views/shortVideo/video";
// import {queryMediaInfoList} from "@/api/video";
import { getSessionItem } from "@/utils/longStorage";
import { queryCommunityList } from "@/api/community";
// import VerticalScreen from "@/components/Video/VerticalScreen"
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import SelectionLinePopup from "@/components/SelectionLinePopup";
import { Toast } from "vant";
import { mapGetters } from "vuex";

export default {
  name: "shortVideo",
  components: {
    Video,
    swiper,
    swiperSlide,
    // SelectionLinePopup
  },
  computed: {
    ...mapGetters({
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  },
  data() {
    return {
      roadLines: [],
      isOpen: false,
      videoInfo: null,
      mediaID: null,
      videoList: [],
      videoPage: 1, //短视频请求页码
      videoSize: 10, //一页短视频的条数
      itemIndex: 0,
      showVideo: true, // 视频是否显示
      showChangeRoadLine: false, // 显示切换路线
      swiperOption: {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: () => {
            this.itemIndex = this.$refs.mySwiper.swiper.activeIndex;
            //滑到倒数第二条数据加载下一页视频数据
            if (this.itemIndex === this.videoList.length - 2) {
              this.videoPage++;
              this.getCommunityList();
            }
            // 如果数据大于3页就删除1页数据
            if (this.videoList.length > 2 * this.videoSize) {
              // console.log("删除")
              this.videoList = this.videoList.splice(0, this.videoSize);
              this.itemIndex = this.itemIndex - this.videoSize;
              this.$refs.mySwiper.swiper.slideTo(this.itemIndex, 0, true);
            }
          },
        },
      },
      swiperOptioncare: {
        direction: "vertical",
        on: {
          slideChangeTransitionEnd: () => {
            this.itemIndex = this.$refs.mySwiper.swiper.activeIndex;
            //滑到倒数第二条数据加载下一页视频数据
            if (this.itemIndex === this.videoList.length - 2) {
              this.videoPage++;
              this.getCommunityList();
            }
            // 如果数据大于3页就删除1页数据
            if (this.videoList.length > 2 * this.videoSize) {
              // console.log("删除")
              this.videoList = this.videoList.splice(0, this.videoSize);
              this.itemIndex = this.itemIndex - this.videoSize;
              this.$refs.mySwiper.swiper.slideTo(this.itemIndex, 0, true);
            }
          },
        },
      },
    };
  },
  created() {
    let appConfig = this.$store.getters.appConfig;
    let imgDomainList = appConfig?.sourceList?.filter(
      (item) => item.type === "VID"
    );
    if (imgDomainList && imgDomainList.length > 0) {
      this.roadLines = imgDomainList[0]?.domain;
    }
  },
  mounted() {
    let query = this.$route.query;
    if (query) {
      this.mediaID = query.id;
      this.getMediaInfoList();
    }
  },
  methods: {
    selectLine(item) {
      this.$store.commit("video/SET_VIDEOROADLINE", item);
      Toast("切换成功");
      this.isOpen = false;
    },
    async getMediaInfoList() {
      let videoInfo = getSessionItem("currentWorks");
      if (videoInfo) {
        this.videoInfo = JSON.parse(videoInfo);
        this.videoList = this.videoList.concat([this.videoInfo]);
      }
      await this.getCommunityList();
    },
    async getCommunityList() {
      /**
       * 帖子发现列表
       */
      let req = {
        pageNumber: this.videoPage,
        pageSize: this.videoSize,
        tag: "最新",
        reqDate: new Date().toISOString(),
        newsType: "SP",
      };
      let res = await this.$Api(queryCommunityList, req);
      if (res && res.code == 200)
        var spList = res.data.list.filter((item) => {
          return item.newsType == "SP";
        });
      if (spList.length == 0 && res.data.list.length > 0) {
        this.videoPage++;
        await this.getCommunityList();
      }
      this.videoList = this.videoList.concat(spList);
    },
    openSelectLine() {
      console.log(123)
      this.isOpen = true;
    },
    // openSelectLine() {
    //   this.showChangeRoadLine = true;
    // },
    // // 关闭线路选择
    // closeSelectLine() {
    // this.showVideo = false;
    // this.showChangeRoadLine = false;
    // this.$nextTick(() => {
    //     this.showVideo = true;
    // });
    // },
  },
};
</script>
<style lang="scss" scoped>
.lineBox {
  position: fixed;
  bottom: 185px;
  right: 60px;
  height: 89px;
  width: 100px;
  border-radius: 10px 10px 10px 0;
  background: #FFFFFF;
  //background: linear-gradient(to bottom, #99d8dc 10%, #ffffff 100%);
  .line {
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #333333;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .selectedLine {
    color: white;
    // background: rgb(132, 164, 249);
  }
}
.short_video {
  height: 100%;
  width: 100%;
  background: #000000;
  position: relative;
  .video-swiper {
    height: 100%;
    width: 100%;

    .my_swiper_silde {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
